import React, { Fragment, useState, useRef } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import validate from 'validate.js';
import classnames from 'classnames';

import './App.css';

const DAYS = [
  /*
  [
    { title: '11/10 Kulturmöten & kulturanpassning', active: true },
    { title: '12/10 Grundkursen', active: true },
    { title: '13/10 Fördjupningskurs 1', active: true },
    { title: '16/10 Fördjupningskurs 2', active: true },
  ]*/
  [
    { title: '24/4 Kulturmöten & kulturanpassning', active: true },
    { title: '25/4 Grundkursen', active: true },
    { title: '26/4 Fördjupningskurs 1', active: true },
    { title: '29/4 Fördjupningskurs 2', active: true },
  ],/*
  [
    { title: '18/10 Kulturmöten & kulturanpassning', active: true },
    { title: '20/10 Kulturmöten & kulturanpassning', active: true },
    { title: '21/10 Grundkursen', active: true },
    { title: '22/10 Fördjupningskurs 1', active: true },
    { title: '25/10 Fördjupningskurs 2', active: true },
  ],
  [
    { title: '12/10 Kulturmöten & kulturanpassning', active: true },
    { title: '13/10 Grundkursen', active: true },
    { title: '14/10 Fördjupningskurs 1', active: true },
    { title: '17/10 Fördjupningskurs 2', active: true },
  ],*/
];

function App() {
  const formRef = useRef();
  const [errors, setErrors] = useState(false);
  const [attendees, setAttendeesCount] = useState(null);
  const [participationDays, setpParticipationDays] = useState({});
  const [delivery, setDelivery] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [saved, setSaved] = useState(false);

  const participateDay = (checked, participantNr, day) => {
    const participationDaysNew = {...participationDays};
    if (!participationDaysNew[`participant-${participantNr}`]) participationDaysNew[`participant-${participantNr}`] = [];
    if (checked) {
      participationDaysNew[`participant-${participantNr}`].push(day.title);
    } else {
      participationDaysNew[`participant-${participantNr}`] = participationDaysNew[`participant-${participantNr}`].filter((dayTitle) => dayTitle !== day.title);
    }
    setpParticipationDays(participationDaysNew);
  };
  const doValidation = () => {
    const constraints = {
      name: { presence: { allowEmpty: false, message: '^Obligatorisk' } },
      email: {
        presence: { allowEmpty: false, message: '^Obligatorisk' },
        email: {
          message: "^Du måste ange en korrekt e-postadress"
        },
      },
      telephone: { presence: { allowEmpty: false, message: '^Obligatorisk' } },
      organisation: { presence: { allowEmpty: false, message: '^Obligatorisk' } },
      adress1: { presence: { allowEmpty: false, message: '^Obligatorisk' } },
      postnumber: { presence: { allowEmpty: false, message: '^Obligatorisk' } },
      postadress: { presence: { allowEmpty: false, message: '^Obligatorisk' } },
      comply: { presence: { message: '^Obligatorisk' }, inclusion: { within: ['true'], message: '^Obligatorisk' } },
    };
    const data = new FormData(formRef.current);
    let validateFields = {
      name: data.get('name'),
      email: data.get('email'),
      telephone: data.get('telephone'),
      organisation: data.get('organisation'),
      adress1: data.get('adress1'),
      postnumber: data.get('postnumber'),
      postadress: data.get('postadress'),
      comply: data.get('comply'),
    };
    Array(attendees).fill().forEach((item, index) => {
      constraints[`participant-firstname-${index}`] = { presence: { allowEmpty: false, message: '^Obligatorisk' } };
      constraints[`participant-lastname-${index}`] = { presence: { allowEmpty: false, message: '^Obligatorisk' } };
      constraints[`participant-email-${index}`] = { presence: { allowEmpty: false, message: '^Obligatorisk' } };
      validateFields[`participant-firstname-${index}`] = data.get(`participant-firstname-${index}`);
      validateFields[`participant-lastname-${index}`] = data.get(`participant-lastname-${index}`);
      validateFields[`participant-email-${index}`] = data.get(`participant-email-${index}`);
    });
    const validation = validate(validateFields, constraints);
    setErrors(validation ? validation : []);
    return validation ? false : true;
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    const ready = doValidation();
    if (ready) {
      const data = new FormData(formRef.current);
      let jsonData = {
        name: data.get('name'),
        email: data.get('email'),
        telephone: data.get('telephone'),
        organisation: data.get('organisation'),
        adress1: data.get('adress1'),
        postnumber: data.get('postnumber'),
        postadress: data.get('postadress'),
        delivery: data.get('delivery'),
        efakturainstr: data.get('enoteinstructions'),
        referensperson: data.get('refperson'),
        referensnummer: data.get('refnumber'),
      };
      jsonData.participants = Array(attendees).fill().map((item, index) => {
        return {
          firstname: data.get(`participant-firstname-${index}`),
          lastname: data.get(`participant-lastname-${index}`),
          email: data.get(`participant-email-${index}`),
          days: participationDays[`participant-${index}`],
        }
      });
      // https://api.alfabetisering.se
      fetch(process.env.REACT_APP_API_URL+'/order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: JSON.stringify(jsonData),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setSaved(true);
        });
    }
  };

  return (
    <Container className="content">
      <Row>
        <Col>
          <h1>MARGARETA MÖRLING - FÖRELÄSARE & FÖRFATTARE</h1>
          <p>← <a href="http://alfabetisering.se/">Tillbaka till hemsidan</a></p>
        </Col>
      </Row>
      <Row className={classnames({'d-none': !saved})}>
        <Col>
          <br />
          <h3>Tack för din anmälan!</h3>
          <p><a href=".">Gör en ny beställning</a></p>
        </Col>
      </Row>
      <Row className={classnames({'d-none': saved})}>
        <Col>
          <Form onSubmit={onSubmit} innerRef={formRef}>
            <h1 className="mt-5">Anmälningsformulär</h1>
            <p>Kostnaden för en temadag är 1 899 kr/deltagare exkl. moms och då ingår föreläsning och dokumentation.</p>
            <p>Kostnaden för föreläsningen <i>Kulturmöten & Kulturanpassning</i> är 350 kr/deltagare exkl. moms och då ingår föreläsning, och dokumentation. <strong>OBS! Föreläsningen är en del av Grundkursen i alfabetisering men ges även separat.</strong></p>
            <p>Vid ev sjukdom av en deltagare rekommenderar vi att hen ersättes av en kollega. Avgiften betalas endast tillbaka vid inskickandet av ett läkarintyg.</p>
            <h3 className="mt-5">Kontaktperson</h3>
            <FormGroup className="mt-3">
              <Label for="name">Namn</Label>
              <Input type="text" invalid={errors.name && true} onChange={() => submitted && doValidation()} name="name" id="name" placeholder="Uppge fullständigt namn" />
              <FormFeedback>{errors.name && errors.name[0]}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="email">E-postadress</Label>
              <Input type="email" invalid={errors.email && true} onChange={() => submitted && doValidation()} name="email" id="email" placeholder="Uppge e-postadress" />
              <FormFeedback>{errors.email && errors.email[0]}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="telephone">Telefon</Label>
              <Input type="text" invalid={errors.telephone && true} onChange={() => submitted && doValidation()} name="telephone" id="telephone" placeholder="Uppge telefonnummer" />
              <FormFeedback>{errors.telephone && errors.telephone[0]}</FormFeedback>
            </FormGroup>
            <hr />
            <h3 className="mt-3">Deltagare</h3>
            <FormGroup className="mt-3">
              <Label for="attendees">Uppge antal deltagare</Label>
              <Input type="select" invalid={submitted && attendees < 1} name="attendees" id="attendees" onChange={(event) => setAttendeesCount(Number(event.target.value))}>
                <option value={0}>Välj antal</option>
                <option value={1}>1 deltagare</option>
                <option value={2}>2 deltagare</option>
                <option value={3}>3 deltagare</option>
                <option value={4}>4 deltagare</option>
                <option value={5}>5 deltagare</option>
                <option value={6}>6 deltagare</option>
                <option value={7}>7 deltagare</option>
                <option value={8}>8 deltagare</option>
                <option value={9}>9 deltagare</option>
                <option value={10}>10 deltagare</option>
                <option value={11}>11 deltagare</option>
                <option value={12}>12 deltagare</option>
                <option value={13}>13 deltagare</option>
                <option value={14}>14 deltagare</option>
                <option value={15}>15 deltagare</option>
              </Input>
              <FormFeedback>Du måste välja minst en deltagare</FormFeedback>
            </FormGroup>
            {attendees && attendees > 0 ? Array(attendees)
              .fill()
              .map((item, index) => (
                <Fragment key={`participant-${index}`}>
                  <Row className="mt-5"><Col><h5>Deltagare {index +1}:</h5></Col></Row>
                  <Row form>
                    <Col md={4}>
                      <FormGroup>
                        <Label for={`participant-firstname-${index}`}>Förnamn</Label>
                        <Input type="text" invalid={errors[`participant-firstname-${index}`] && true} onChange={() => submitted && doValidation()} name={`participant-firstname-${index}`} id={`participant-firstname-${index}`}/>
                        <FormFeedback>{errors[`participant-firstname-${index}`] && errors[`participant-firstname-${index}`][0]}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for={`participant-lastname-${index}`}>Efternamn</Label>
                        <Input type="text" invalid={errors[`participant-lastname-${index}`] && true} onChange={() => submitted && doValidation()} name={`participant-lastname-${index}`} id={`participant-lastname-${index}`}/>
                        <FormFeedback>{errors[`participant-lastname-${index}`] && errors[`participant-lastname-${index}`][0]}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for={`participant-email-${index}`}>E-postadress</Label>
                        <Input type="email" invalid={errors[`participant-email-${index}`] && true} onChange={() => submitted && doValidation()} name={`participant-email-${index}`} id={`participant-email-${index}`} />
                        <FormFeedback>{errors[`participant-email-${index}`] && errors[`participant-email-${index}`][0]}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>Ange dagar:</p>
                      {submitted && (!participationDays[`participant-${index}`] || !participationDays[`participant-${index}`].length) && <p className="text-danger">Välj minst en dag</p>}
                    </Col>
                  </Row>
                  {DAYS.map((group, groupIndex) => (
                    <Row key={`days-group-${groupIndex}`}>
                      <Col>
                        {group.map((day) => (
                          <FormGroup check inline key={`days-${day.title}`}>
                            <Label check>
                              <Input type="checkbox" onChange={(event) => participateDay(event.target.checked, index, day)} /> {day.title}
                            </Label>
                          </FormGroup>
                        ))}
                    </Col>
                  </Row>
                  ))}
                </Fragment>)): null}
            <hr />
            <h3 className="mt-3">Faktureringsuppgifter</h3>
            <FormGroup className="mt-3">
              <Label for="delivery">Hur önskar ni få fakturan levererad?</Label>
              <Input type="select" name="delivery" id="delivery" onChange={(event) => setDelivery(event.target.value)}>
                <option value="POST">Via vanlig postgång</option>
                <option value="PDF">PDF via e-post</option>
                <option value="E-FAKTURA">Uppge särskilda anvisningar för e-faktura</option>
              </Input>
            </FormGroup>
            {delivery === 'E-FAKTURA' && (
              <FormGroup className="mt-3">
                <Label for="enoteinstructions">Instruktioner för e-faktura</Label>
                <Input type="textarea" name="enoteinstructions" id="enoteinstructions" placeholder="Har ni särskilda instruktioner för e-faktura?"/>
              </FormGroup>
            )}
            <FormGroup className="mt-3">
              <Label for="refnumber">Referensnummer/kod (om det ska stå på fakturan)</Label>
              <Input type="text" name="refnumber" id="refnumber" />
            </FormGroup>
            <FormGroup className="mt-3">
              <Label for="refperson">Er referensperson</Label>
              <Input type="text" name="refperson" id="refperson" placeholder="Uppge referenskod för e-faktura"/>
            </FormGroup>
            <FormGroup className="mt-3">
              <Label for="organisation">Fakturamottagare</Label>
              <Input type="text" invalid={errors.organisation && true} onChange={() => submitted && doValidation()} name="organisation" id="organisation" placeholder="Ange vem fakturan ska vara adresserad till"/>
              <FormFeedback>{errors.organisation && errors.organisation[0]}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="adress1">Adress</Label>
              <Input type="text" invalid={errors.adress1 && true} onChange={() => submitted && doValidation()} name="adress1" id="adress1" placeholder="Ange gata eller box nummer"/>
              <FormFeedback>{errors.adress1 && errors.adress1[0]}</FormFeedback>
            </FormGroup>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="postnumber">Postnummer</Label>
                  <Input type="text" invalid={errors.postnumber && true} onChange={() => submitted && doValidation()} name="postnumber" id="postnumber"/>
                  <FormFeedback>{errors.postnumber && errors.postnumber[0]}</FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="postadress">Postadress</Label>
                  <Input type="text" invalid={errors.postadress && true} onChange={() => submitted && doValidation()} name="postadress" id="postadress"/>
                  <FormFeedback>{errors.postadress && errors.postadress[0]}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <FormGroup check>
              <Input type="checkbox" invalid={errors.comply && true} onChange={() => submitted && doValidation()} name="comply" id="comply" value="true" />
              <Label for="comply" check>Ja, vi förstår att anmälan är bindande och att avgiften endast betalas tillbaka vid inskickandet av ett läkarintyg.</Label>
              <FormFeedback>{errors.comply && errors.comply[0]}</FormFeedback>
            </FormGroup>
            <Button className="mt-4 mb-4">Anmäl</Button>
          </Form>
          <hr />
          <p>För mer information kontakta: admin@alfabetisering.se, 070-7515964</p>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
